"use client";
import ScanToDownloadModalSkeleton from "src/screens/(informational)/ScanToDownloadModal/ScanToDownloadModalSkeleton";
import lazy from "src/swsh-native/lazy";
import { ModalConfig } from "../types";

const ScanToDownload = lazy(() => import("src/screens/(informational)/ScanToDownloadModal"), {
	loading: ScanToDownloadModalSkeleton,
});

type InformationalModal = {
	ScanToDownload: {
		props: {
			url: string;
		};
	};
};

export default InformationalModal;

export const InformationalModalConfig = {
	ScanToDownload: {
		component: ScanToDownload,
		variant: "Tall",
	},
} satisfies ModalConfig<InformationalModal>;
