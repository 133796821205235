import { track } from "@amplitude/analytics-react-native";
import { useCallback, useEffect, useRef } from "react";
import { amp } from "src/api/amplitude";
import { ModalType } from "../../../types";
import useModalState from "../../useModalState";
import { PresentModalRegularProps, PresentModalType } from "../types";

const _useModal = <T extends ModalType>() => {
	const openedModalsToCloseOnUnmount = useRef<string[]>([]);
	const handlePresentModal = useCallback<PresentModalType<T>>((modal, ...params) => {
		const options = params[0] ?? {};
		const { closeOnHookUnmount = true } = options as PresentModalRegularProps;

		if (closeOnHookUnmount) {
			openedModalsToCloseOnUnmount.current.push(modal as string);
		}
		track(amp.modal.presentModal, { modalName: modal });
		const { open, getModalConfig } = useModalState.getState();
		const config = getModalConfig(modal as string);
		const props = { ...config?.defaultProps, ...options?.props };

		open(modal as string, {
			props: props,
		});
	}, []);
	const timeout = useRef<NodeJS.Timeout | null>(null);
	const _presentModal = useCallback<PresentModalType<T>>(
		(modal, ...params) => {
			const options = params[0] ?? {};
			if (options?.delay && options.delay > 0) {
				setTimeout(() => {
					handlePresentModal(modal, ...params);
				}, options.delay);
			} else {
				handlePresentModal(modal, ...params);
			}
		},
		[handlePresentModal],
	);
	useEffect(() => {
		const curTimeout = timeout.current;
		return () => {
			if (curTimeout) {
				clearTimeout(curTimeout);
			}
		};
	}, []);

	const handleCloseOpenedModalsOnUnmount = useCallback(() => {
		const { close } = useModalState.getState();
		openedModalsToCloseOnUnmount.current.forEach((modal) => {
			close(modal);
		});
		openedModalsToCloseOnUnmount.current = [];
	}, []);

	return {
		_presentModal,
		openedModals: openedModalsToCloseOnUnmount,
		handleCloseOpenedModalsOnUnmount,
	};
};

export default _useModal;
