import { useCallback, useEffect, useRef } from "react";
import { Timestamp_MS } from "src/shared/types/general";

const useClearingTimeouts = () => {
	const timeouts = useRef<NodeJS.Timeout[]>([]);
	useEffect(() => {
		const curTimeouts = timeouts.current;
		return () => {
			curTimeouts.forEach((timeout) => {
				clearTimeout(timeout);
			});
		};
	}, []);

	const createClearingTimeout = useCallback((fn: () => void, timeout: Timestamp_MS) => {
		timeouts.current.push(setTimeout(fn, timeout));
	}, []);

	return {
		timeouts,
		createClearingTimeout,
	};
};
export default useClearingTimeouts;
