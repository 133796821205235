"use client";
import { StyleProp, View, ViewStyle } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Link } from "solito/link";
import DownloadOnAppStore from "src/assets/images/informational/DownloadOnAppStore.png";
import GetItOnGooglePlayBadge from "src/assets/images/informational/GetItOnGooglePlayBadge.png";
import { APP_STORE_URL } from "src/constants/appStore";
import cursor from "src/constants/cursor";
import { GOOGLE_PLAY_STORE_URL } from "src/constants/googlePlayStore";
import useDevice from "src/hooks/useDevice";
import { useModal } from "src/modals";
import InformationalModal from "src/modals/configs/InformationalModal";
import { Image } from "src/swsh-native";

const SourceLookup = {
	GooglePlay: {
		src: GetItOnGooglePlayBadge,
		aspectRatio: 270 / 80,
		url: GOOGLE_PLAY_STORE_URL,
		alt: "Download on Google Play",
		testID: "DownloadOnGooglePlay",
	},
	AppStore: {
		src: DownloadOnAppStore,
		aspectRatio: 498 / 167,
		url: APP_STORE_URL,
		alt: "Download on App Store",
		testID: "DownloadOnAppStore",
	},
};

const DownloadBadge = ({
	style,
	variant,
}: {
	style?: StyleProp<ViewStyle>;
	variant: "GooglePlay" | "AppStore";
}) => {
	const { device } = useDevice();
	const height = device === "mobile" ? 40 : 52;
	const { presentModal } = useModal<InformationalModal>();
	const { src, aspectRatio, url, alt, testID } = SourceLookup[variant];

	const Content = (
		<Image
			enteringAnimation={false}
			priority="high"
			src={src}
			alt={alt}
			dim={{
				height,
				aspectRatio,
			}}
		/>
	);

	return (
		<View
			testID={testID}
			style={[
				{
					height,
					aspectRatio,
					cursor: cursor.pointer,
				},
				style,
			]}
		>
			{device === "mobile" ? (
				<Link href={url} target="_blank">
					{Content}
				</Link>
			) : (
				<TouchableOpacity
					onPress={() => {
						presentModal("ScanToDownload", {
							props: {
								url,
							},
						});
					}}
				>
					{Content}
				</TouchableOpacity>
			)}
		</View>
	);
};
export default DownloadBadge;
