import { useEffect } from "react";
import { MODAL_PRELOADING } from "src/api/featureFlags";
import useClearingTimeouts from "src/hooks/useClearingTimeouts";
import useModalState from "../../useModalState";

const usePreloadModal = () => {
	const { createClearingTimeout } = useClearingTimeouts();
	useEffect(() => {
		if (!MODAL_PRELOADING) return;
		createClearingTimeout(() => {
			const { configLookup } = useModalState.getState();
			for (const [key, value] of Object.entries(configLookup)) {
				if ("preload" in value.component && value.component.preload) {
					value.component.preload();
				}
			}
		}, 500);
	}, [createClearingTimeout]);
};
export default usePreloadModal;
