export const paddingXS = 2;
export const paddingSmall = 4;
export const paddingMedium = 8;
export const paddingLarge = 16;
export const paddingXL = 20;
export const paddingXXL = 24;
export const paddingXXXL = 32;

export const paddingSmallSpacer = 50;
export const paddingSpacer = 64;
export const paddingLargeSpacer = 100;
