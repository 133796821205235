import type { Transition } from "framer-motion";
import { AnimatedMenuRoute } from "src/components/AnimatedMenu/types";

export const InformationalNavRoutes: AnimatedMenuRoute[] = [
	{
		title: "FAQ",
		href: "/faq",
	},
	{
		title: "OUR STORY",
		href: "/our-story",
	},
	{
		title: "CAREERS",
		href: "/careers",
	},
	{
		title: "UPDATES",
		href: "/updates",
	},
	{
		title: "TERMS",
		href: "/terms",
	},
	{
		title: "PRIVACY",
		href: "/policy",
	},
];

export const TransitionConfig = {
	duration: 0.4,
	delay: 0.2,
} satisfies Transition;
