/* eslint-disable react-hooks/rules-of-hooks */
// Prefetch is static so it shouldn't change order of hook renders
import { useApolloClient } from "@apollo/client";
import { useEffect, useRef } from "react";
import useClearingTimeouts from "src/hooks/useClearingTimeouts";
import useModalState from "../../useModalState";
import { ModalPrefetchProps } from "../types";

const useHandleModalPrefetch = (
	prefetch: Record<string, ModalPrefetchProps<any> | undefined> | undefined,
) => {
	if (!prefetch) return;
	const apolloClient = useApolloClient();
	const apolloClientRef = useRef(apolloClient);
	useEffect(() => {
		apolloClientRef.current = apolloClient;
	}, [apolloClient]);
	const prefetchRef = useRef(prefetch);
	const { createClearingTimeout } = useClearingTimeouts();

	useEffect(() => {
		createClearingTimeout(() => {
			const { getModalConfig } = useModalState.getState();
			if (!prefetchRef.current) return;
			for (const key in prefetchRef.current) {
				const prefetchValue = prefetchRef.current[key];
				if (!prefetchValue) continue;
				const config = getModalConfig(key);
				if (!config) continue;

				const prefetchPromise = config.prefetch?.(prefetchValue.props, {
					apolloClient: apolloClientRef.current,
				});
				useModalState.setState((state) => ({
					prefetchPromiseLookup: {
						...state.prefetchPromiseLookup,
						[key]: prefetchPromise,
					},
				}));
			}
		}, 500);
	}, [createClearingTimeout]);
};
export default useHandleModalPrefetch;
