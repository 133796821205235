import useDevice from "src/hooks/useDevice";

const useNavBarSpacing = (): { paddingLeft: number; paddingRight: number; height: number } => {
	const { device } = useDevice();

	return device === "mobile"
		? {
				paddingLeft: 16,
				paddingRight: 16,
				height: 80,
			}
		: {
				paddingLeft: 80,
				paddingRight: 80,
				height: 100,
			};
};
export default useNavBarSpacing;
