import { ReactNode } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import useDevice from "src/hooks/useDevice";
import { paddingXL } from "src/styles/spacing";
import useNavBarSpacing from "../hooks/useNavBarSpacing";

const TextSectionContainer = ({
	style,
	children,
}: {
	style?: StyleProp<ViewStyle>;
	children?: ReactNode;
}) => {
	const { device } = useDevice();
	const { paddingLeft } = useNavBarSpacing();

	return (
		<View
			style={[
				{
					justifyContent: "center",
				},
				device === "desktop"
					? {
							flex: 1,
							maxWidth: "50%",
							marginLeft: paddingLeft,
							marginRight: paddingXL,
						}
					: {
							width: "100%",
							alignItems: "center",
							paddingHorizontal: paddingXL,
						},
				style,
			]}
		>
			{children}
		</View>
	);
};
export default TextSectionContainer;
