import type { Variants } from "framer-motion";
import { CSSProperties, useMemo } from "react";
import { useWindowDimensions } from "react-native";
import getOptimizedVideoUrl from "src/assets/__generated__/videos/marketing_home/getOptimizedVideoUrl";
import useDevice from "src/hooks/useDevice";
import { TransitionConfig } from "src/screens/(informational)/constants";
import useNavBarSpacing from "src/screens/(informational)/hooks/useNavBarSpacing";
import { paddingLarge } from "src/styles/spacing";
import { MDiv } from "src/swsh-native";

const ContainerVariantsDesktop = {
	visible: {
		transform: "translateX(0px)",
		opacity: 1,
	},
	default: {
		transform: "translateX(100px)",
		opacity: 0.5,
	},
} satisfies Variants;
const ContainerVariantsMobile = {
	visible: {
		transform: "translateX(0px)",
		opacity: 1,
	},
	default: {
		transform: "translateX(0px)",
		opacity: 0,
	},
} satisfies Variants;

const VideoSection = ({ style }: { style?: CSSProperties }) => {
	const { device } = useDevice();
	const dim = useWindowDimensions();
	const { paddingRight } = useNavBarSpacing();
	const { webmVideoUrl, mp4VideoUrl, movVideoUrl } = useMemo(() => {
		return {
			webmVideoUrl: getOptimizedVideoUrl({ size: device, format: "webm" }),
			mp4VideoUrl: getOptimizedVideoUrl({ size: device, format: "mp4" }),
			movVideoUrl: getOptimizedVideoUrl({ size: device, format: "mov" }),
		};
	}, [device]);
	const { width, aspectRatio, maxHeight } =
		device === "mobile"
			? {
					width: dim.width - 2 * paddingLarge,
					aspectRatio: 469 / 390,
					maxHeight: 1 * dim.height,
				}
			: {
					width: 0.5 * dim.width,
					aspectRatio: 469 / 390,
					maxHeight: 0.7 * dim.height,
				};
	const height = Math.min(maxHeight, width * aspectRatio);

	return (
		<MDiv
			initial="default"
			whileInView="visible"
			viewport={{ once: true }}
			variants={device === "mobile" ? ContainerVariantsMobile : ContainerVariantsDesktop}
			transition={
				device === "mobile"
					? TransitionConfig
					: {
							duration: TransitionConfig.duration + TransitionConfig.delay,
						}
			}
			style={[
				{
					justifyContent: "center",
				},
				device === "mobile"
					? {
							alignItems: "center",
							marginTop: paddingLarge,
						}
					: {
							alignItems: "flex-start",
							marginRight: paddingRight,
						},
				style,
			]}
		>
			<video width={width} height={height} autoPlay muted loop playsInline>
				<source src={webmVideoUrl} type="video/webm" />
				<source src={movVideoUrl} type="video/quicktime" />
				<source src={mp4VideoUrl} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		</MDiv>
	);
};
export default VideoSection;
